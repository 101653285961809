/* eslint-disable react/jsx-pascal-case */
import React from "react";
import ContactUs from "../../Components/ContactUs/ContactUs";
import Products from "../../Components/ProductsCard/Products";
import PublicCarousel from "../../Components/PublicCarousel/PublicCarousel";
import RequestForService from "../../Components/RequestForService/RequestForService";
import NoticeCircularsButton from "../../Reuseable-Components/Notice&CircularsButton/NoticeCircularsButton";
import PublicCarousel_v2 from "../../Components/public_carousel_v2/Public_Carousel_v2";

import BannerDesktop3 from "../../Assets/banner_desktop.png";
import BannerTab3 from "../../Assets/banner_tab.png";
import BannerMobile3 from "../../Assets/banner_mobile.png";
import { Link } from "react-router-dom";
import { IoArrowForwardCircleSharp } from "react-icons/io5";

export default function Home({
  showHomeCarousel,
  setShowHomeCarousel,
  videoModalIsOpen,
  setVideoModalIsOpen,
}) {
  return (
    <>
      <NoticeCircularsButton
        setVideoModalIsOpen={setVideoModalIsOpen}
        setShowHomeCarousel={setShowHomeCarousel}
        videoModalIsOpen={videoModalIsOpen}
      />
      {/* <PublicCarousel /> */}

      {/* <div className="block lg:hidden">
        {showHomeCarousel ? (
          <div>
            <PublicCarousel_v2 />
          </div>
        ) : null}
      </div> */}

      {/* <div className="hidden lg:block">
        <PublicCarousel_v2 />
      </div> */}

      <Link to="/careers" target="_blank">
        <section className="hidden sm:hidden md:hidden lg:block cursor-pointer relative">
          <div>
            <img
              className="w-full"
              src={BannerDesktop3}
              alt="Recruitment Banner"
            />
          </div>

          <div className="absolute top-[15%] left-1/2 transform -translate-x-1/2 w-full text-center">
            <p className="font-semibold text-2xl md:text-3xl lg:text-4xl text-[#e8851b] animate-pulse">
              Recruitment for multiple positions at SISCO Bank
            </p>
          </div>

          <div className="absolute top-[70%] left-1/2 transform -translate-x-1/2 flex flex-col items-center">
            <button className="flex items-center gap-x-3 text-lg md:text-xl lg:text-2xl text-[#0e381d] bg-white border-white border px-6 py-2 rounded shadow-md">
              <IoArrowForwardCircleSharp size={24} />
              <span className="animate-pulse hover:animate-none">
                Know More
              </span>
            </button>
          </div>
          <div className="absolute top-[85%] left-0 flex flex-col items-center w-full">
            <p className="text-center text-base mt-3 text-white italic px-9">
              Online application will be open from 24-Mar-2025 (10.00 am) to
              23-Apr-2025 (5.00 pm)
            </p>
          </div>
        </section>
      </Link>

      <Link to="/careers" target="_blank">
        <section className="hidden md:block lg:hidden relative">
          <div className="hidden md:block lg:hidden">
            <img src={BannerTab3} alt="Recruitment Banner" />
          </div>

          <p className="absolute top-[15%] text-[16px] left-[50%] -translate-x-1/2 font-semibold text-[#e8851b] animate-pulse [animation-duration:0.7s] [animation-timing-function:step-start]">
            Recruitment for multiple positions at SISCO Bank
          </p>

          <div className="absolute top-[70%] left-[47%] -translate-x-1/2 flex flex-col items-center">
            <div className="flex items-center gap-x-3 text-sm text-[#0e381d] bg-white border-white border px-4 py-1 rounded">
              <IoArrowForwardCircleSharp size={24} />
              <p className="animate-pulse [animation-duration:0.7s] hover:animate-none">
                Know More
              </p>
            </div>

            {/* Text placed right below the button */}
            <p className="text-center text-sm mt-2 text-white">
              Online application will be open from 24-Mar-2025 (10.00 am) to
              23-Apr-2025 (5.00 pm)
            </p>
          </div>
        </section>
      </Link>

      <Link to="/careers" target="_blank">
        <section className="relative block sm:block md:hidden lg:hidden">
          <div>
            <img src={BannerMobile3} alt="Recruitment Banner" />
          </div>

          <div className="absolute top-[25%] left-1/2 transform -translate-x-1/2 w-full">
            <div className="animate-pulse text-2xl font-semibold text-[#e8851b]  text-center">
              <p className=" ">Recruitment for multiple</p>
              <p className="">positions at SISCO Bank</p>
            </div>
          </div>

          <div className="absolute top-[65%] left-[50%] transform -translate-x-1/2 flex flex-col items-center">
            <button className="flex items-center gap-x-1 text-xs text-[#0e381d] bg-white border-white border p-2 rounded shadow-md">
              <IoArrowForwardCircleSharp size={16} />
              <span className="animate-pulse hover:animate-none">
                Know More
              </span>
            </button>
          </div>
          <div className="absolute top-[75%] left-[0%] flex flex-col items-center">
            {/* Text placed right below the button */}
            <p className=" text-sm mt-2 text-white  px-6 font-semibold">
              Online application will be open from 24-Mar-2025 (10.00 am) to
              23-Apr-2025 (5.00 pm)
            </p>
          </div>
        </section>
      </Link>

      <RequestForService />
      <Products />
      {/* <FB_Carousel /> */}
      {/* <Blogs /> */}
      <ContactUs />
    </>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Heading from "../font/Heading";
import { Link } from "react-router-dom";
import moment from "moment";

export default function MoreDetailsPage() {
  const [showOnlineApplicationLink, setShowOnlineApplicationLink] =
    useState(true);

  let currentDate = moment().format("YYYY-MM-DD");

  // Function to check if a date is between 24th March 2025 and 7th April 2025
  const isDateInRange = () => {
    const startDate = moment("2025-03-24", "YYYY-MM-DD");
    const endDate = moment("2025-04-07", "YYYY-MM-DD");
    return moment(currentDate, "YYYY-MM-DD").isBetween(
      startDate,
      endDate,
      undefined,
      "[]"
    );
  };

  useEffect(() => {
    const isDateRange = isDateInRange();

    if (isDateRange) {
      // setShowOnlineApplicationLink(true);
    }
    // console.log("getCountdownHours", data);
    // console.log("isDateRange", isDateRange);
  }, [showOnlineApplicationLink]);

  return (
    <>
      <div className="container mx-auto py-10 h-screen">
        <Heading classes="mb-6">More Details</Heading>

        <Link to="/careers">
          <div className="flex justify-end py-3 ">
            <span className="cursor-pointer border px-2 py-1 md:px-6 md:py-2 hover:underline text-sm md:text-base">
              back
            </span>
          </div>
        </Link>

        <p className="bg-yellow-100 border p-4 sm:p-10 md:p-8 lg:p-10 text-base sm:text-lg leading-relaxed">
          If you encounter any payment or form-related issues, please send an
          email to{" "}
          <strong className="italic underline break-words">
            <a href="mailto:agm.humanresources@siscobank.com">
              agm.humanresources@siscobank.com
            </a>
          </strong>{" "}
          with a detailed description of the problem. Make sure to include your
          Unique ID or contact details, along with relevant transaction
          screenshots for reference.
        </p>

        <div className="py-6 ">
          <Link
            to="https://files.siscobank.com/_ADVERTISEMENT_SISCO_2025.pdf"
            target="_blank"
          >
            <p className="text-sm md:text-lg font-semibold pb-3">
              1. Advertisement for the posts of Assitant staff officer and
              Technical Assistants.{" "}
              <span className="text-blue-700 italic text-xs md:text-sm cursor-pointer hover:underline hover:underline-offset-2">
                (click here to download)
              </span>
            </p>
          </Link>

          <Link
            to="https://files.siscobank.com/SISCO_Instructions_for_Online_Application%20(FINAL)%20(1)%20(1).pdf"
            target="_blank"
          >
            <p className="text-sm md:text-lg font-semibold pb-3 ">
              2. Instruction for online application{" "}
              <span className="text-blue-700 italic  text-xs md:text-sm cursor-pointer hover:underline hover:underline-offset-2">
                (click here to download)
              </span>
            </p>
          </Link>

          {showOnlineApplicationLink ? (
            <>
              <Link to="https://nerecruitment.in/nerecruit11/" target="_blank">
                <p className="text-sm md:text-lg font-semibold pb-3 ">
                  3. Online Application
                  <span className="text-blue-700 italic  text-xs md:text-sm cursor-pointer hover:underline hover:underline-offset-2">
                    {" "}
                    (click here to apply online )
                  </span>
                </p>
              </Link>
            </>
          ) : (
            <>
              <p className="text-sm md:text-lg font-semibold pb-3 ">
                3.The online application will be available starting from March
                24, 2025.
                <span className="text-blue-700 italic  text-xs md:text-sm cursor-pointer hover:underline hover:underline-offset-2">
                  {" "}
                  {/* (click here to apply online ) */}
                </span>
              </p>
            </>
          )}
        </div>
      </div>
    </>
  );
}

import React from "react";
import Heading from "../../Reuseable-Components/font/Heading";
import CarrersCard from "../../Reuseable-Components/CarrersCard/CarrersCard";

const Careers = () => {
  let cardDetails = [
    {
      heading: `Advertisement for the posts of Assitant Staff Officer and Technical Assistants `,
      pillTitle: "Banking",
      postName: "Assitant staff officer and Technical Assistants",
      NumberOfVaccancies:
        "Assitant Staff Officer - 10 & Technical Assistants - 4  ",
      ApplyMode: "Online (24th March,2025 - 23rd April, 2025)",
      hasMoreDocs: true,
    },
    // {
    //   heading:
    //     "ENGAGEMENT OF CHIEF INFORMATION SECURITY OFFICER (CISO) ON CONTRACTUAL BASIS",
    //   pillTitle: "Information Technology",
    //   postName: "Chief Information Security Officer (CISO) GRADE –VI",
    //   NumberOfVaccancies: "",
    //   ApplyMode: "1(one) Apply Mode- offline",
    //   hasMoreDocs: false,
    // },
  ];

  return (
    <div className="container mx-auto py-10">
      <div className="px-3">
        <Heading classes="mb-6">Career</Heading>

        <div className=" md:flex md:justify-center md:gap-x-6 ">
          {cardDetails.map((cardObj, index) => (
            <div className="py-4 md:py-0 md:px-6">
              <CarrersCard key={index} cardDetails={cardObj} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Careers;

import React, { useEffect } from "react";

import { useState } from "react";
import GenericModal from "../Reuseable-Components/modals/GenericModal";
import { IoMdClose } from "react-icons/io";

import useAxios from "../hooks/useAxios";
import moment from "moment/moment";
import GeneralCarousel from "../Reuseable-Components/Carousel/GeneralCarousel";
import SubHeading from "../Reuseable-Components/font/SubHeading";

import adImg from "../Assets/SISCO_Adv_Update.jpg";

const GET_POPUP_MODAL_HOMEPAGE = "api/popup-homepage-modals?populate=*";

export default function NoticePopupModal_v2() {
  const { data, error, loading } = useAxios(GET_POPUP_MODAL_HOMEPAGE);
  const [modalIsOpen, setModalIsOpen] = useState(true);

  let currentDate = moment().format("YYYY-MM-DD");
  const startdate = data?.[0]?.attributes?.start_date;
  const enddate = data?.[0]?.attributes?.end_date;

  //   console.log("data", data);
  //   console.log(
  //     "iamge-url",
  //     data?.[0]?.attributes?.desktop_image?.data?.[0]?.attributes?.url
  //   );

  useEffect(() => {
    setModalIsOpen(true);
  }, []);

  if (currentDate >= startdate && currentDate < enddate) {
    return modalIsOpen ? (
      <>
        <div className="overflow-hidden z-auto">
          <GenericModal
            open={modalIsOpen}
            setOpen={setModalIsOpen}
            title={""}
            isLarge={true}
            isAdd={false}
          >
            <div
              className=" relative text-slate-600 pb-3 left-[90%] lg:left-[100%] cursor-pointer "
              onClick={() => setModalIsOpen(false)}
            >
              <IoMdClose size={24} color="white" />
            </div>

            <div className="z-auto">
              <div className="block md:hidden">
                <div class=" relative w-full overflow-hidden">
                  <div class="  relative  mb-3 mx-auto">
                    <SubHeading classes={`text-center uppercase`}></SubHeading>

                    <div className="flex flex-col gap-10">
                      <img
                        src={adImg}
                        // src={
                        //   data?.[0]?.attributes?.mobile_image?.data?.[0]
                        //     ?.attributes?.url
                        // }
                        className="block "
                        alt="Wild Landscape"
                      />
                      {/* <img
                        src={
                          data?.[0]?.attributes?.mobile_image?.data?.[1]
                            ?.attributes?.url
                        }
                        className="block "
                        alt="Wild Landscape"
                      /> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="hidden md:block lg:hidden">
                <div class=" relative w-full overflow-hidden">
                  <div class="  relative  w-[80%] mb-3 mx-auto">
                    <SubHeading classes={`text-center uppercase`}></SubHeading>

                    <div className="flex flex-col gap-10">
                      <img
                        // src={
                        //   data?.[0]?.attributes?.tablet_image?.data?.[0]
                        //     ?.attributes?.url
                        // }
                        src={adImg}
                        className="block "
                        alt="Wild Landscape"
                      />
                      {/* <img
                        src={
                          data?.[0]?.attributes?.tablet_image?.data?.[1]
                            ?.attributes?.url
                        }
                        className="block "
                        alt="Wild Landscape"
                      /> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="hidden md:hidden lg:block">
                <div class=" overflow-hidden">
                  <div class="  ">
                    <SubHeading classes={`text-center uppercase`}></SubHeading>

                    <div className=" ">
                      <img
                        src={adImg}
                        // src={
                        //   data?.[0]?.attributes?.desktop_image?.data?.[0]
                        //     ?.attributes?.url
                        // }
                        className="block "
                        alt="Wild Landscape"
                      />

                      {/* <img
                        src={
                          data?.[0]?.attributes?.desktop_image?.data?.[1]
                            ?.attributes?.url
                        }
                        className="block w-1/2"
                        alt="Wild Landscape"
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </GenericModal>
        </div>
      </>
    ) : null;
  }
}
